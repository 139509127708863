import axios, { AxiosError } from 'axios';
const configJSON = require('../components/config');

export const getClient = () => {
  const baseUrl = configJSON.baseUrl;
  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return client;
};

export const getErrorMessage = (err: Error) => {
  const DEFAULT_MESSAGE = 'Unexpected error happened';
  if (err instanceof AxiosError) {
    return err.response?.data?.message || DEFAULT_MESSAGE;
  }
  return DEFAULT_MESSAGE;
};

export const getErrorObj = (err: Error) => {
  const DEFAULT_MESSAGE = 'Unexpected error happened';
  if (err instanceof AxiosError) {
    return err.response?.data;
  }
  return { message: DEFAULT_MESSAGE };
};
