import { Message } from '../../../framework/Message';
import { BlockComponent } from '../../../framework/BlockComponent';
import { message as MESSAGE } from 'antd';
import axios from 'axios';
import { getErrorMessage } from '../../../controllers/api';

export const configJSON = require('../../config');

export interface Props {
  history: any;
  location: any;
}
interface S {
  otp: any;
  phone: any;
  loader: any;
}
interface SS {
  id: any;
}
export default class OtpCheckController extends BlockComponent<Props, S, SS> {
  apiRegisterAccount: string = '';
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = this.getEmptyState();
  }
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      phone: this.props.location.state?.phone,
    });
  }
  getEmptyState = () => ({
    otp: '',
    phone: '',
    loader: false,
  });

  onHandleOtpChange = (e: any) => {
    this.setState({
      otp: e,
    });
  };

  handleResendOTP = () => {
    axios({
      method: configJSON.apiPostMethod,
      url: configJSON.baseUrl + configJSON.otpResendEndpoint,
      // data: {phone: this.state.phone, code: "US"},
      data: { phone: this.state.phone },
      headers: { 'Content-Type': configJSON.formUrlenCodedContentType },
    })
      .then((res) => {
        if (res.data.success) {
          MESSAGE.success('OTP sent to mobile number', 5);
        } else {
          MESSAGE.error('There was an error sending OTP to your phone.', 5);
        }
      })
      .catch((err: Error) => {
        MESSAGE.error(getErrorMessage(err), 500);
      });
  };

  onHandleOtpCheck = () => {
    this.setState({
      loader: true,
    });
    const data = {
      // phone_number: "+1" + this.state.phone,
      phone_number: this.state.phone,
      code: this.state.otp,
      username: this.props.location.state.username,
    };
    if (this.state.otp !== '' || this.state.phone !== '') {
      axios({
        method: configJSON.apiPostMethod,
        url: configJSON.baseUrl + configJSON.otpCheckEndPoint,
        data: data,
        headers: { 'Content-Type': configJSON.formUrlenCodedContentType },
      })
        .then((res) => {
          this.setState({
            loader: false,
          });
          if (res.data.success === true) {
            MESSAGE.success(res.data.message, 5);
            this.props.history.push(res.data.data.redirect_url);
          } else {
            MESSAGE.error(res.data.message, 5);
          }
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          console.log('error message: ', getErrorMessage(err));
          MESSAGE.error(getErrorMessage(err), 5);
        });
    }
  };
}
