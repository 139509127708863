import React from 'react';
import { withRouter } from 'react-router-dom'; // Import withRouter
import { BlockComponent } from '../../../framework/BlockComponent';
import EmailSentIcon from '../../style/icons/email_sent.svg';
import Card from '../../../common/Card';
import styles from '../../../common/card.module.scss';
import emailSentStyles from './emailSent.module.scss';

export const configJSON = require('../../config');

export interface Props {
  location?: any;
  history: any;
}

interface S {
  email: string;
  loader: boolean;
  email_sent: boolean;
  errorText: string;
}

interface SS {
  id: any;
}

class EmailSent extends BlockComponent<Props, S, SS> {
  // Define redirectTimeout as a class property
  redirectTimeout?: NodeJS.Timeout;
  constructor(props: Props) {
    super(props);
    this.state = this.getEmptyState();
  }

  getEmptyState = () => ({
    email: '',
    loader: false,
    email_sent: false,
    errorText: '',
    redirectTimeout: '',
  });

  emailSentMessage = (
    <>
      <div>
        <img src={EmailSentIcon} className={styles.emailIcon} />
        <p
          style={{
            fontWeight: '500',
          }}
          className={emailSentStyles.emailSentSubHeading}
        >
          Link to Verify Your Account has been sent to your registered email,
          Please Check Your Mail
        </p>
      </div>
      <p className={emailSentStyles.emailSentFooterClass}>
        Questions? Reach out to us at &nbsp;
        <a href="mailto:support@bitnimbus.io"> support@bitnimbus.io</a>
      </p>
    </>
  );

  async componentDidMount() {
    this.redirectTimeout = setTimeout(() => {
      this.props.history.push('/');
    }, 10000);
  }

  async componentWillUnmount() {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout);
    }
  }

  render() {
    const { loader } = this.state;

    return (
      <div>
        <div className="ct_main_bg">
          <div className="container-fluid">
            {loader ? (
              <div className="screen-loader-center">
                <div className="ct_center_loader">
                  <span className="loader"></span>
                </div>
              </div>
            ) : (
              <Card
                title={
                  this.props.location?.state?.event === 'emailResent'
                    ? 'Verify your email to login'
                    : 'Verify your email to complete the sign up'
                }
              >
                {this.emailSentMessage}
              </Card>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// Wrap the component with withRouter to get access to history
export default EmailSent;
